import colors from './colors'
import { Mode, Mood, Theme } from './types'

const theme: Theme = {
  appBarHeight: 64,
  borderColor: colors.white + '00',
  borderRadius: 8,
  breakpoints: {
    mobile: 768,
  },
  colors,
  fadedOpacity: 0.5,
  fontSizes: {
    sm: 14,
    lg: 32,
    md: 14,
    xlg: 48,
    xsm: 11,
  },
  inputSizes: {
    sm: 36,
    lg: 56,
    md: 44,
  },
  mobileTopBarHeight: 50,
  moodColor: colors.primary.main,
  mode: 'dark',
  mood: 'bullish',
  onSetMode: (mode: Mode) => {},
  onSetMood: (mood?: Mood) => {},
  siteWidth: 1100,
  spacing: [0, 8, 16, 32, 48, 64, 80, 96, 128, 156],
  surfaces: {
    '-1': {
      backgroundColor: '',
      boxShadow: '',
    },
    '0': {
      backgroundColor: '',
      boxShadow: '',
    },
    '1': {
      backgroundColor: '',
      boxShadow: '',
    },
    '2': {
      backgroundColor: '',
      boxShadow: '',
    },
    '3': {
      backgroundColor: '',
      boxShadow: '',
    },
  },
  textColor: '#FFFFFF',
}

export default theme
