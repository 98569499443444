/*--------------------CONVERSIONS--------------------*/
export const APPROVE_TOKEN_CONVERSION_SUCCESS = 'approve_token'
export const APPROVE_TOKEN_CONVERSION_REJECTION = 'approve_token_rejection'
export const APPROVE_TOKEN_CONVERSION_FAILURE = 'approve_token_failure'

export const EXECUTE_TRADE_CONVERSION_SUCCESS = 'execute_trade'
export const EXECUTE_TRADE_CONVERSION_REJECTION = 'execute_trade_rejection'
export const EXECUTE_TRADE_CONVERSION_FAILURE = 'execute_trade_failure'

/*--------------------EVENTS--------------------*/
export const AVATAR2099_BUY_LISTING = 'avatar2099_buy_listing'
export const AVATAR2099_CANCEL_LISTING = 'avatar2099_cancel_listing'
export const AVATAR2099_CLAIM_PACK = 'avatar2099_claim_pack'
export const AVATAR2099_CREATE_LISTING = 'avatar2099_create_listing'
export const AVATAR2099_MINT_PACK = 'avatar2099_mint_pack'
export const AVATAR2099_UPDATE_LISTING = 'avatar2099_update_listing'
export const AVATAR2099_UPDATE_WARDROBE = 'avatar2099_update_wardrobe'

export const ADD_WATCHLIST_EVENT = 'add_watchlist_token'
export const CONNECT_WALLET_EVENT = 'connect_wallet_mobile_waitlist'
export const CHAT_SEND_MESSAGE_EVENT = 'chat_send_message'
export const CHAT_SEND_SPAM_EVENT = 'chat_spam_message'
export const CHAT_SHARE_TRADE_EVENT = 'chat_share_trade'
export const CREATE_USER_EVENT = 'create_user'
export const DOWNLOAD_TRADE_HISTORY_EVENT = 'download_trade_history'
export const EXECUTE_WRAP_OR_UNWRAP_SUCCESS = 'execute_wrap_or_unwrap_success'
export const EXECUTE_WRAP_OR_UNWRAP_REJECTION =
  'execute_wrap_or_unwrap_rejection'
export const EXECUTE_WRAP_OR_UNWRAP_FAILURE = 'execute_wrap_or_unwrap_failure'
export const EXPLORE_NOW_ON_DESKTOP_MODAL_IMPRESSION =
  'explore_now_on_desktop_modal_impression'
export const EXPLORE_NOW_ON_DESKTOP_MODAL_TRADE_NOW_CTA_CLICK =
  'explore_now_on_desktop_modal_trade_now_cta_click'
export const MOBILE_WAITLIST_PAGE_LOAD = 'mobile_waitlist_reward_page_load'
export const MOBILE_WAITLIST_REFERRAL_SHARE = 'mobile_waitlist_referral_share'
export const NETWORK_SWITCH_WALLET_EVENT_SUCCESS = 'network_switch'
export const NETWORK_SWITCH_WALLET_EVENT_REJECTION = 'network_switch_rejection'
export const OPEN_WAITLIST_LOOTBOX = 'open_waitlist_lootbox'
export const REMOVE_WATCHLIST_EVENT = 'remove_watchlist_token'
export const SIGN_USER_EVENT = 'sign_message_user'
export const UNSUBSCRIBE_EVENT = 'unsubscribe'
export const UPDATE_USER_EVENT = 'update_user'
export const VERIFY_BETA_CODE_EVENT = 'verify_beta_code'
export const SUSPICIOUS_MODAL_IMPRESSION = 'suspicious_modal_impression'
export const SUSPICIOUS_MODAL_REPORT_MISTAKE_EVENT =
  'suspicious_modal_report_mistake'

/*-----------------OPTIMISM EVENTS-----------------*/
export const OPTIMISM_LANDING_PAGE_LOAD = 'optimism_landing_page_load'
