export function generateColors(h: number, s: number, l: number, c: number) {}

export function darken(hexColor: string, strength = 70) {
  const { h, s } = hexToHsl(hexColor)
  return hslToHex(h, s, 100 - strength)
}

export function lighten(hexColor: string, strength = 90) {
  const { h, s } = hexToHsl(hexColor)
  return hslToHex(h, s, strength)
}

export function hexToHsl(hex: string) {
  const { r, g, b } = hexToRgb(hex)
  return rgbToHsl(r, g, b)
}

function hexToRgb(hex: string) {
  const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i)
  if (!m) {
    return {
      r: 0,
      g: 0,
      b: 0,
    }
  }
  return {
    r: parseInt(m[1], 16),
    g: parseInt(m[2], 16),
    b: parseInt(m[3], 16),
  }
}

export function hslToHex(h: number, s: number, l: number) {
  const { r, g, b } = hslToRgb(h, s, l)
  return rgbToHex(r, g, b)
}

function hslToRgb(h: number, s: number, l: number) {
  const sf = s / 100
  const lf = l / 100
  let c = (1 - Math.abs(2 * lf - 1)) * sf
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  let m = lf - c / 2
  let r = 0
  let g = 0
  let b = 0

  if (0 <= h && h < 60) {
    r = c
    g = x
    b = 0
  } else if (60 <= h && h < 120) {
    r = x
    g = c
    b = 0
  } else if (120 <= h && h < 180) {
    r = 0
    g = c
    b = x
  } else if (180 <= h && h < 240) {
    r = 0
    g = x
    b = c
  } else if (240 <= h && h < 300) {
    r = x
    g = 0
    b = c
  } else if (300 <= h && h < 360) {
    r = c
    g = 0
    b = x
  }

  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)
  return { r, g, b }
}

function rgbToHex(r: number, g: number, b: number) {
  let rf = r.toString(16)
  let gf = g.toString(16)
  let bf = b.toString(16)

  if (rf.length === 1) rf = '0' + rf
  if (gf.length === 1) gf = '0' + gf
  if (bf.length === 1) bf = '0' + bf

  return '#' + rf + gf + bf
}

function rgbToHsl(r: number, g: number, b: number) {
  const rf = r / 255
  const bf = b / 255
  const gf = g / 255

  const cmin = Math.min(rf, gf, bf)
  const cmax = Math.max(rf, gf, bf)
  const delta = cmax - cmin

  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) {
    h = 0
  } else if (cmax === rf) {
    h = ((gf - bf) / delta) % 6
  } else if (cmax === gf) {
    h = (bf - rf) / delta + 2
  } else {
    h = (rf - gf) / delta + 4
  }

  h = Math.round(h * 60)

  if (h < 0) {
    h += 360
  }

  l = (cmax + cmin) / 2
  s = 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return { h, s, l }
}
