import React from 'react'

import { IconProps } from '../Icon'

const Candlesticks: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path
      fill={color}
      d="M12 2H11V5H10V15H11V18H12V15H13V5H12V2ZM6 6H5V9H4V19H5V22H6V19H7V9H6V6ZM17 6H18V9H19V19H18V22H17V19H16V9H17V6Z"
    />
  </svg>
)

export default Candlesticks
