import { useEffect, useMemo, useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { hslToHex } from './helpers'
import theme from './theme'
import { Color, Mood, ThemeColor, ThemeSurfaces } from './types'

interface ThemeProviderProps {
  children?: React.ReactNode
}
const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState('dark')
  const [mood, onSetMood] = useState<Mood>('bullish')

  const {
    bearish,
    bullish,
    black,
    borderColor,
    grey,
    moodColor,
    surfaces,
    textColor,
    white,
    primary,
    secondary,
  } = useMemo(() => {
    const hue = 265
    const primaryHue = 140
    const secondaryHue = 5
    const bullishHue = 140
    const bearishHue = 5

    let bearishColor: string
    let bullishColor: string
    let brightness: number
    let newBorderColor: string
    let newSurfaces: ThemeSurfaces
    let newBlack: string
    let newGrey: Color
    let newWhite: string
    let newTextColor: string
    let saturation: number
    let newPrimary: ThemeColor
    let newSecondary: ThemeColor
    let newMoodColor: string

    switch (mode) {
      case 'light':
        brightness = 100
        saturation = 10
        newBlack = hslToHex(hue, saturation, 10)
        newWhite = hslToHex(hue, saturation, 97)
        newGrey = {
          100: hslToHex(hue, saturation, brightness),
          200: hslToHex(hue, saturation, brightness),
          300: hslToHex(hue, saturation, brightness),
          400: hslToHex(hue, saturation, brightness),
          500: hslToHex(hue, saturation, brightness - 5),
          600: hslToHex(hue, saturation, brightness - 10),
          700: hslToHex(hue, saturation, brightness - 20),
          800: hslToHex(hue, saturation, brightness - 40),
          900: hslToHex(hue, saturation, brightness - 60),
        }
        newBorderColor = hslToHex(hue, saturation, brightness - 20)
        newPrimary = {
          dark: hslToHex(primaryHue, 100, 35),
          light: hslToHex(primaryHue, 100, 35),
          main: hslToHex(primaryHue, 100, 35),
          extraLight: hslToHex(primaryHue, 100, 35),
        }
        newSecondary = {
          dark: hslToHex(secondaryHue, 100, 50),
          light: hslToHex(secondaryHue, 100, 50),
          main: hslToHex(secondaryHue, 100, 50),
          extraLight: hslToHex(secondaryHue, 100, 50),
        }
        bearishColor = hslToHex(bearishHue, 100, 50)
        bullishColor = hslToHex(bullishHue, 100, 40)
        newSurfaces = {
          '-1': {
            backgroundColor: newGrey[900],
            boxShadow: ``,
          },
          '0': {
            backgroundColor: newWhite,
            boxShadow: `0px`,
          },
          '1': {
            backgroundColor: newWhite,
            boxShadow: `0px 8px 12px -4px ${newBlack}`,
          },
          '2': {
            backgroundColor: newWhite,
            boxShadow: `0px 4px 24px -8px ${newBlack}`,
          },
          '3': {
            backgroundColor: newWhite,
            boxShadow: `0px 8px 24px 0px ${newBlack}`,
          },
        }
        newTextColor = newBlack
        document.documentElement.style.backgroundColor = newGrey[900]
        //document.documentElement.style.backgroundImage = `radial-gradient(circle at top left, #121b21, #211220, #1b1221)`
        document.body.style.color = newBlack
        break
      case 'dark':
      default:
        brightness = 3
        saturation = 10
        newBlack = hslToHex(hue, saturation, brightness)
        newWhite = hslToHex(hue, saturation, 97)
        bearishColor = hslToHex(bearishHue, 90, 65)
        bullishColor = hslToHex(bullishHue, 100, 70)
        newGrey = {
          100: hslToHex(hue, saturation, 80 + brightness),
          200: hslToHex(hue, saturation, 60 + brightness),
          300: hslToHex(hue, saturation, 40 + brightness),
          400: hslToHex(hue, saturation * 0.7, 20 + brightness),
          500: hslToHex(hue, saturation * 0.75, 15 + brightness),
          600: hslToHex(hue, saturation * 0.85, 14 + brightness),
          700: hslToHex(hue, saturation * 0.95, 8 + brightness),
          800: hslToHex(hue, saturation, 4 + brightness),
          900: hslToHex(hue, saturation, 2 + brightness),
        }
        newPrimary = {
          dark: hslToHex(primaryHue, 100, 50),
          light: hslToHex(primaryHue, 100, 50),
          main: hslToHex(primaryHue, 100, 70),
          extraLight: hslToHex(primaryHue, 100, 50),
        }
        newSecondary = {
          dark: hslToHex(secondaryHue, 100, 70),
          light: hslToHex(secondaryHue, 100, 70),
          main: hslToHex(secondaryHue, 90, 65),
          extraLight: hslToHex(secondaryHue, 100, 70),
        }
        newBorderColor = newGrey[600]
        newSurfaces = {
          '-1': {
            backgroundColor: newBlack,
            boxShadow: ``,
          },
          '0': {
            backgroundColor: newGrey[900],
            boxShadow: `0px`,
          },
          '1': {
            backgroundColor: newGrey[800],
            boxShadow: `0px 8px 12px -4px ${newBlack}`,
          },
          '2': {
            backgroundColor: newGrey[700],
            boxShadow: `0px 4px 24px -8px ${newBlack}`,
          },
          '3': {
            backgroundColor: newGrey[600],
            boxShadow: `0px 8px 24px 0px ${newBlack}`,
          },
        }
        newTextColor = newWhite
        document.documentElement.style.backgroundColor = newGrey[900]
        //document.documentElement.style.backgroundImage = `radial-gradient(circle at top left, #121b21, #211220, #1b1221)`
        document.body.style.color = newWhite
    }

    newMoodColor = mood === 'bullish' ? newPrimary.main : newPrimary.main

    return {
      bearish: bearishColor,
      black: newBlack,
      bullish: bullishColor,
      borderColor: newBorderColor,
      grey: newGrey,
      moodColor: newMoodColor,
      surfaces: newSurfaces,
      textColor: newTextColor,
      white: newWhite,
      primary: newPrimary,
      secondary: newSecondary,
    }
  }, [mode, mood])

  useEffect(() => {
    if (mood === 'bullish') {
      //document.documentElement.style.background = `radial-gradient(circle at top right, #001514, #130c17, #0d0911)`
    }
    if (mood === 'bearish') {
      // document.documentElement.style.background = `radial-gradient(circle at top right, #170008, #130c17, #0d0911)`
    }
  }, [mood])

  return (
    <StyledThemeProvider
      theme={{
        ...theme,
        borderColor,
        colors: {
          ...theme.colors,
          bearish,
          bullish,
          black,
          grey,
          white,
          primary,
          secondary,
        },
        fadedOpacity: mode === 'dark' ? 0.5 : 0.66,
        mode,
        mood,
        moodColor,
        onSetMode: setMode,
        onSetMood,
        surfaces,
        textColor,
      }}
    >
      {children}
    </StyledThemeProvider>
  )
}

export default ThemeProvider
