import React from 'react'

import { IconProps } from '../Icon'

const CaretUp: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={color} d="M7 14l5-5 5 5z" />
  </svg>
)

export default CaretUp
