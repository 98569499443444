import { useCallback, useState } from 'react'
import animateScrollTo from 'animated-scroll-to'
import ismobilejs from 'ismobilejs'
import { getAnalytics, logEvent } from 'firebase/analytics'

import styled from 'styled-components'

import useDimensions from 'hooks/useDimensions'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Text from 'components/Text'
import Loader from 'components/Loader'

const Hero: React.FC = () => {
  const [{ width = 0 }, ref] = useDimensions()
  const analytics = getAnalytics()

  const isMobile = ismobilejs(window.navigator).phone

  const [emailAddress, setEmailAddress] = useState('')
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setError(undefined)
    setLoading(false)
    setEmailAddress(value)
    setSuccess(false)
  }, [])

  const handleClick = useCallback(async () => {
    if (!emailAddress) {
      setError('Please enter an email address')
      setSuccess(false)
      return
    }
    logEvent(analytics, 'promo_email_sign_up')
    setLoading(true)
    await fetch(
      'https://us-central1-slingshot-feb2f.cloudfunctions.net/promotions-signUp',
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ data: { email: emailAddress } }),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.result === 'success') {
          setLoading(false)
          setError(undefined)
          setEmailAddress('')
          setSuccess(true)
          return
        }
        setError(res.error.message)
        setLoading(false)
        setSuccess(false)
      })
  }, [analytics, emailAddress])

  const handleSubmit = useCallback(
    async (e: React.KeyboardEvent) => {
      if (e.key !== 'Enter') return
      handleClick()
    },
    [handleClick],
  )

  return (
    <Wrapper isMobile={isMobile}>
      <ContentWrapper>
        <MainWrapper ref={ref}>
          <div>
            <TextWrapper
              style={{
                fontSize: width / 14,
              }}
            >
              No OP Tokens Left
            </TextWrapper>
          </div>
          <div>
            <Text>Sign up to get notified about upcoming promos.</Text>
            {/* <div style={{ height: '12px' }} /> */}
          </div>
          <div>
            <EmailWrapper>
              <EmailInput
                autoFocus
                id="top-input"
                type="text"
                placeholder="Enter email address"
                onKeyDown={handleSubmit}
                onChange={handleChange}
                value={emailAddress}
              ></EmailInput>
            </EmailWrapper>
            {error && <div>{error}</div>}
          </div>
          <Button
            disabled={success || !!error || loading}
            onClick={handleClick}
            size="lg"
          >
            {loading ? (
              <Loader size={24} />
            ) : success ? (
              'Signed up!'
            ) : (
              'SIGN UP'
            )}
          </Button>
        </MainWrapper>
        <LearnMore
          onClick={() => {
            animateScrollTo(document.getElementById('limited')!)
          }}
        >
          <Icon faded name="chevron-down" size={32} />
        </LearnMore>
      </ContentWrapper>
    </Wrapper>
  )
}

const LearnMore = styled.div`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.spacing[3]}px;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
  background: url(/${({ isMobile }) => (isMobile ? 'mobile' : 'hero')}-bg.jpg)
    no-repeat center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  max-height: 800px;
  min-height: 500px;
  overflow: hidden;
  position: relative;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const ContentWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  left: 0;
  margin: ${(props) => props.theme.spacing[5]}px auto 0;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 80px;
`

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: Sequel Black;
  gap: 24px;
  justify-content: center;
  text-align: center;
  text-shadow: 0px 0px 24px ${(props) => props.theme.colors.grey[900]},
    0px 0px 48px ${(props) => props.theme.colors.grey[900]};
`

const EmailWrapper = styled.div`
  height: 3.125rem;
  background: $white;
  border-radius: 8px;
  display: flex;
  width: 500px;
  border: 4px solid $white;
  max-width: 39.5rem;
  margin: 0 auto 0.5rem;
`

const EmailInput = styled.input`
  height: 100%;
  flex-grow: 1;
  border-radius: 8px;
  outline: 0;
  border: 0;
  margin-left: 0.5rem;
  font-family: Sequel Sans;
  text-indent: 20px;
`

export default Hero
