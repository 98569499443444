import React from 'react'

import { IconProps } from '../Icon'

const DoubleArrow: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <polygon fill={color} points="15.5,5 11,5 16,12 11,19 15.5,19 20.5,12" />
    <polygon fill={color} points="8.5,5 4,5 9,12 4,19 8.5,19 13.5,12" />
  </svg>
)

export default DoubleArrow
