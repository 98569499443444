import React from 'react'

import { IconProps } from '../Icon'

const ArrowDownward: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
      fill={color}
    />
  </svg>
)

export default ArrowDownward
