import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import Text from 'components/Text'

const TimelineSection: React.FC = () => {
  const isMobile = ismobilejs(window.navigator).phone
  return (
    <Wrapper id="limited" isMobile={isMobile}>
      <ContentWrapper>
        <MainWrapper>
          <Text black size="lg">
            Promo has ended
          </Text>
          <div>
            <Text>
              100k tokens have been accounted for across thousands of users.
            </Text>
            <div style={{ height: '12px' }} />
            <Text faded size="sm">
              If you are eligible for rewards, your OP tokens will be claimable
              by September 30, 11:59PM PST.
            </Text>
          </div>
        </MainWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '61px' : '122px')} 0 122px;
  overflow: hidden;
  position: relative;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

export default TimelineSection
