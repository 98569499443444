import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import IconButton from 'components/IconButton'
import Logo from 'components/Logo'
import Text from 'components/Text'

const FooterSection: React.FC = () => {
  const isMobile = ismobilejs(window.navigator).phone
  return (
    <>
      <ContentWrapper>
        <MainWrapper>
          <LogoWrapper isMobile={isMobile}>
            <SlingshotLogo>
              <Logo />
              SLINGSHOT
            </SlingshotLogo>
          </LogoWrapper>
          <Actions>
            <IconButton
              faded
              iconSize={16}
              name="twitter"
              size="sm"
              onClick={() => {
                window.open('https://twitter.com/SlingshotCrypto')
              }}
            />
            <IconButton
              faded
              name="discord"
              onClick={() => {
                window.open('https://discord.com/invite/H3UcvgtASR')
              }}
              iconSize={16}
              size="sm"
            />
          </Actions>
          <Text faded size="sm">
            *OP token price is ~$1.00 as of September 19th, 2022, and may be
            subject to change.
          </Text>
          <LinkWrapper>
            <a
              href="https://slingshot.finance/terms"
              style={{ fontSize: '14px', opacity: 0.5 }}
            >
              Terms and Conditions
            </a>
            <a
              href="https://slingshot.finance/privacy"
              style={{ fontSize: '14px', opacity: 0.5 }}
            >
              Privacy Policy
            </a>
          </LinkWrapper>
          <Text size="sm">Copyright 2022 Slingshot Finance</Text>
        </MainWrapper>
      </ContentWrapper>
      <div
        className="terms"
        style={{ fontSize: '9px', padding: '50px', opacity: 0.5 }}
      >
        <p>SLINGSHOT PROMOTION</p>
        <p>OFFICIAL RULES</p>
        <p>VOID WHERE PROHIBITED.</p>
        <p>
          These official rules govern Slingshot Finance’s promotion (the
          “Promotion”) sponsored by DEXAG, Inc. (the “Company,” “Sponsor”, "we",
          "us" or "or"). You must agree to the Terms and Conditions, available
          at{' '}
          <a href="https://slingshot.finance/terms">
            https://slingshot.finance/terms
          </a>
          , and these official rules (the “Rules”) to participate in the
          Promotion and to be eligible to receive the reward or other incentive
          (the “Reward”) included in the Promotion. These Rules are incorporated
          into and part of the Terms and Conditions. Capitalized terms used in
          these Rules have the meanings set forth in the Terms and Conditions,
          unless otherwise defined herein.
        </p>
        <p>
          ‍‍BY ENTERING THE PROMOTION, YOU ACKNOWLEDGE AND AGREE TO THE TERMS
          AND CONDITIONS AND THESE RULES AND THE FACT THAT ALL DECISIONS OF THE
          SPONSOR, IN ITS SOLE DISCRETION, RELATING TO THE PROMOTION ARE FINAL.
          IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS AND THESE RULES, YOU
          MAY NOT PARTICIPATE IN THE PROMOTION OR RECEIVE ANY REWARDS.
        </p>
        <p>DURATION AND ENTRY</p>
        <p>
          The Promotion will begin and end at the times specified by the Company
          (the “Promotion Period”) in its official communications sent to or
          otherwise made available to you regarding the Promotion, such as via
          the Company’s Twitter, Discord, website and/or promotion landing page
          (the “Communications”). Entry methods will be specified in the
          Communications and must be followed to receive a Reward. The Company’s
          computer is the official timekeeping device for entering the
          Promotion. By entering this Promotion, you acknowledge that you have
          read and agreed to the Terms and Conditions and these Rules.
        </p>
        <p>REWARDS</p>
        <p>
          Rewards will be calculated and delivered to eligible users (the
          “Winners”) as follows:
        </p>
        <table border={1}>
          <tbody>
            <tr>
              <td>Tier</td>
              <td>Optimism Swap Requirement</td>
              <td>Reward</td>
            </tr>
            <tr>
              <td>1</td>
              <td>[$100, $1000)</td>
              <td>1 OP token</td>
            </tr>
            <tr>
              <td>2</td>
              <td>[$1000, $10000)</td>
              <td>5 OP tokens</td>
            </tr>
            <tr>
              <td>3</td>
              <td>&nbsp;[$10000, )</td>
              <td>25 OP tokens</td>
            </tr>
          </tbody>
        </table>
        <p>
          The value of Rewards may fluctuate from the time that a Promotion is
          announced to the time that Rewards are delivered to Winners as a
          result of market developments or other factors, so any stated Reward
          is an estimate and may differ from what is actually delivered to a
          Winner.
        </p>
        <p>
          The Company reserves the right to revoke any Promotion or other offer
          at its sole discretion, at any time, without prior notice to you. The
          Company further reserves the right to suspend, cancel or change the
          terms (including these Rules), duration, eligibility criteria,
          Rewards, calculation of Rewards, method for delivering Rewards and
          timing of the delivery of Rewards, for any Promotion, at any time, for
          any reason, without prior notice to you. For the avoidance of doubt,
          the Company may in its sole discretion and without prior notice to you
          substitute a Reward for a different Reward with equal or greater
          value. If the Promotion is canceled or modified, we may elect, in our
          sole discretion, to deliver Rewards to eligible users and we will have
          no further obligation to any user in connection with the Promotion.
          Rewards are not redeemable for cash or other Digital Assets from the
          Company. Rewards are non-transferable and no substitution will be made
          except at the Company’s sole discretion.
        </p>
        <p>
          By entering the Promotion, you acknowledge that any decisions made by
          the Company related to the Promotion are final and made at the
          Company’s sole discretion. Reward details not specifically stated in
          these Rules will be determined in the Company’s sole discretion.
        </p>
        <p>ELIGIBILITY</p>
        <p>
          Limited time offer. The Promotion is open to anyone who is 18 years
          old or over, and located in a jurisdiction where the Company offers
          its Services. Void where prohibited. Users who place a certain volume
          of transactions on Optimism via the Services during the Promotion
          period may be eligible to receive Rewards while supplies last. Users
          must follow any additional eligibility requirements described in the
          Communications to be eligible.
        </p>
        <p>
          Any user who does not meet the conditions in these Rules is not
          eligible for this Promotion. The Company may decline requests to
          enroll in a Promotion without notice and at its sole discretion. The
          Company may disqualify any entry if we reasonably believe that such
          entry or entrant has violated the Terms and Conditions or these Rules,
          including, without limitation: (1) by tampering with the entry process
          or the operation of the Promotion, including wallet addresses that are
          reasonably determined to be fraudulent or duplicates; (2) by acting in
          an unsportsmanlike or disruptive manner, or with intent to annoy,
          abuse, threaten or harass any other person; or (3) by being on any
          prohibited persons list issued by any government or governmental
          agency that has jurisdiction or authority over the Company or its
          affiliates.
        </p>
        <p>
          Employees, officers and representatives, and the immediate family
          members of such employees, officers and representatives (i.e., parent,
          spouse, siblings, children, grandparents, step parents, step children
          and step siblings, and their respective spouses, and those living in
          the same household, whether or not related) of the Company are not
          eligible to enter any Promotions or receive Rewards.
        </p>
        <p>AWARDING REWARDS</p>
        <p>
          The Company will deliver Rewards to each Winner’s wallet address or
          otherwise make Rewards available to such Winners, such as through a
          Company-sponsored interface through which Winners can claim their
          Rewards, by the delivery date applicable to the Promotion. Details on
          the delivery of Rewards, notifications to Winners and delivery date
          will be described in the Communications applicable to each Promotion.
        </p>
        <p>
          The Company reserves the right to deem a reward forfeited and reclaim
          such reward if: (1) we determine, in our sole discretion, that a
          Winner violated the Terms and Conditions or these Rules; or (2) within
          30 days of the Company awarding the Reward to a Winner, the Winner has
          not fulfilled its obligations described in the Rules, including with
          respect to claiming a Reward.
        </p>
        <p>ACKNOWLEDGEMENTS; WAIVERS</p>
        <p>
          You acknowledge that your participation in the Promotion is subject to
          these Rules and the Terms and Conditions (including, without
          limitation, sections regarding Warranty Disclaimers, Indemnity,
          Limitation of Liability and Dispute Resolution). For the avoidance of
          doubt, and unless otherwise prohibited by law, by entering the
          Promotion you waive any right to, and shall hold harmless the Company
          from, any claim, liability, loss, damage (including punitive,
          incidental, and consequential damages) or expense (including
          attorneys’ fees) arising out of or in connection with your
          participation in the Promotion or the acceptance, use or misuse of any
          Reward.
        </p>
        <p>
          By entering the Promotion, you acknowledge that any decisions made by
          the Company related to the Promotion are final and made at the
          Company’s sole discretion. You acknowledge further that the Company,
          its affiliates and its and their respective officers, directors,
          employees, agents and representatives will not be responsible or
          liable for: (1) lost, late, misdirected, damaged, illegible,
          incomplete or incorrect entries for the Promotion; (2) lost, late or
          misdirected Reward notices; (3) any typographical, transmission or
          other error in the printing of the Promotion or Rewards,
          administration of the Promotion or announcement of the Rewards; (4)
          telephone, electronic, hardware, software, network, internet or
          computer malfunctions, failures or difficulties affecting or delaying
          your entry in the Promotion; or (5) any condition caused by events
          beyond the Company’s reasonable control that may cause the Promotion
          to be disrupted or corrupted or otherwise limit or restrict you
          ability to participate in the Promotion.
        </p>
        <p>
          You acknowledge that the Company is subject to economic restrictions
          and trade sanctions requirements; as such, we reserve the right to
          deny delivery of any Reward when required by applicable law. You waive
          the right to assert as a cost of winning a Reward, any costs
          associated with claiming or seeking to claim a Reward, or using a
          Reward.
        </p>
        <p>TAXES</p>
        <p>
          You acknowledge that all income taxes resulting from your receipt of a
          Reward are your sole responsibility. The Company will not be liable
          for any tax-related effects of winning or transferring a Reward. If
          you win one or more Rewards through any Promotions totaling an amount
          in excess of US$600 in any calendar year, the Company may be required
          to report such winnings to the Internal Revenue Service and send you
          an applicable IRS Form 1099, in which case, you shall provide the
          Company with any necessary information required to complete and send
          you such form. Failure to provide this information may mean that you
          forfeit one or more Rewards. You should consult an accountant or tax
          professional to determine tax implications in accepting and using
          (including conducting transactions involving) any Reward.
        </p>
      </div>
    </>
  )
}

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[2]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
`

const LogoWrapper = styled.div<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  gap: 24px;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`

const SlingshotLogo = styled.div`
  align-items: center;
  display: flex;
  font-family: Sequel Black;
  font-size: 14px;
  gap: 16px;
  letter-spacing: 3px;
`
export default FooterSection
