import React from 'react'

import { IconProps } from '../Icon'

const Github: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 88 87" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill={color}
      d="M44 0C19.7047 0 0 19.9695 0 44.5997C0 64.3053 12.606 81.0228 30.0923 86.9211C32.2887 87.3336 33 85.951 33 84.7766V76.4736C20.7607 79.1719 18.2123 71.2108 18.2123 71.2108C16.2103 66.0559 13.3247 64.6844 13.3247 64.6844C9.33167 61.9155 13.629 61.975 13.629 61.975C18.0473 62.2872 20.372 66.5725 20.372 66.5725C24.2953 73.3888 30.6643 71.419 33.176 70.278C33.5683 67.3976 34.7087 65.4277 35.97 64.3165C26.1983 63.1829 15.9243 59.3585 15.9243 42.2731C15.9243 37.4006 17.644 33.4238 20.4563 30.3018C20.0017 29.1756 18.4947 24.6376 20.8853 18.4977C20.8853 18.4977 24.5813 17.301 32.989 23.0692C36.498 22.0806 40.26 21.5863 44 21.5677C47.74 21.5863 51.5057 22.0806 55.022 23.0692C63.4223 17.301 67.111 18.4977 67.111 18.4977C69.5053 24.6413 67.9983 29.1793 67.5437 30.3018C70.367 33.4238 72.072 37.4043 72.072 42.2731C72.072 59.4031 61.7797 63.1755 51.9823 64.2793C53.559 65.6619 55 68.375 55 72.5377V84.7766C55 85.9622 55.704 87.3559 57.937 86.9174C75.4087 81.0116 88 64.2979 88 44.5997C88 19.9695 68.299 0 44 0Z"
    />
  </svg>
)

export default Github
