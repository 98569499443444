import React, { useCallback } from 'react'

import { getAnalytics, logEvent } from 'firebase/analytics'
// import { analytics } from './clientApp'

import AnaltyicsContext from './AnalyticsContext'
import { GAEventCustomParams, GAEventRequest } from './types'

interface AnalyticsProviderProps {
  children: React.ReactNode
}

const AnaltyicsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const analytics = getAnalytics()
  const handleSendGAEvent = useCallback(
    (request: GAEventRequest) => {
      logEvent(analytics, request.name, getGACustomParamsFromRequest(request))
    },
    [analytics],
  )

  return (
    <AnaltyicsContext.Provider
      value={{
        onSendGAEvent: handleSendGAEvent,
      }}
    >
      {children}
    </AnaltyicsContext.Provider>
  )
}

const getGACustomParamsFromRequest = (request: GAEventRequest) => {
  const params = {} as GAEventCustomParams
  for (let field in request) {
    if (field !== 'name') params[field] = request[field]
    if (field === 'account') params[field] = `"${request[field]}"`
  }
  // params.traffic_type =
  //   process.env.NODE_ENV !== 'production' ? 'internal' : 'external'

  return params
}

export default AnaltyicsProvider
