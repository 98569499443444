import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import Layer3Logo from 'components/Layer3Logo'
import Logo from 'components/Logo'
import Text from 'components/Text'

const FooterSection: React.FC = () => {
  const isMobile = ismobilejs(window.navigator).phone
  return (
    <>
      <ContentWrapper>
        <MainWrapper>
          <LogoWrapper isMobile={isMobile}>
            <SlingshotLogo>
              <Logo />
              SLINGSHOT
            </SlingshotLogo>
            <Icon faded name="close" />
            <Layer3Logo />
          </LogoWrapper>
          <Actions>
            <IconButton
              faded
              iconSize={16}
              name="twitter"
              size="sm"
              onClick={() => {
                window.open('https://twitter.com/SlingshotCrypto')
              }}
            />
            <IconButton
              faded
              name="discord"
              onClick={() => {
                window.open('https://discord.com/invite/H3UcvgtASR')
              }}
              iconSize={16}
              size="sm"
            />
          </Actions>
          <Text faded size="sm">
            *OP token price is ~$1.50 as of August 15th, 2022, and may be
            subject to change.
          </Text>
          <span>
            <Text faded size="sm">
              Read Slingshot Terms and Conditions and Privacy Policy&nbsp;
            </Text>
            <a
              href="https://slingshot.finance/terms"
              style={{ fontSize: '14px', opacity: 0.5 }}
            >
              here
            </a>
          </span>
          <Text size="sm">Copyright 2022 Slingshot Finance</Text>
        </MainWrapper>
      </ContentWrapper>
      <div
        className="terms"
        style={{ fontSize: '9px', padding: '50px', opacity: 0.5 }}
      >
        <p>SLINGSHOT PROMOTION</p>
        <p>OFFICIAL RULES</p>
        <p>VOID WHERE PROHIBITED.</p>
        <p>
          These official rules govern Slingshot’s Promotion sponsored by DEXAG,
          Inc. ("we", "our", "us", “Slingshot”, or “Sponsor”). These rules
          constitute a binding agreement between you and the Sponsor if you
          participate in the Promotion. These rules incorporate the Slingshot
          Terms of Service (“Terms”).
        </p>
        <p>
          ‍BY ENTERING THE PROMOTION, YOU ACKNOWLEDGE AND AGREE TO THESE RULES
          AND THE FACT THAT ALL DECISIONS OF THE SPONSOR ARE FINAL IN ALL
          REGARDS RELATING TO THE PROMOTION. IF YOU DO NOT AGREE TO THESE RULES,
          IN FULL, YOU MAY NOT PARTICIPATE IN THE PROMOTION.
        </p>
        <ol>
          <li>
            HOW TO ENTER. The Promotion will begin and end at the times
            specified by Slingshot (the “Promotion Period”). Entry methods for
            the Promotion will be specified by Slingshot and all entries must be
            received during the Promotion Period. Slingshot’s computer is the
            official timekeeping device for entry in the Promotion.
          </li>
          <li>
            REWARDS.
            <table border={1}>
              <tbody>
                <tr>
                  <td>Tier</td>
                  <td>Optimism Trade Requirement</td>
                  <td>Reward</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>[$100, $300)</td>
                  <td>1 OP token</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>[$300, $600)</td>
                  <td>3 OP tokens</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>&nbsp;[$600, 1200)</td>
                  <td>6 OP tokens</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>[$1200, )</td>
                  <td>12 OP tokens</td>
                </tr>
              </tbody>
            </table>
            Rewards are non-transferable and no substitution will be made except
            as provided herein at the Sponsor’s sole discretion. The value of
            your reward may increase or decrease in value by the time it's
            delivered to your wallet as a result of market developments or other
            factors. Sponsor reserves the right where lawful to substitute a
            reward for one of equal or greater value if the designated reward
            should become unavailable for any reason. Rewards consist of only
            the items specifically listed as part of the reward. In no event
            will more than the stated number of rewards be awarded. Winners are
            solely responsible for any/all applicable federal, state and local
            taxes and any other expenses related to the acceptance and use of a
            reward not specified herein. Reward details not specifically stated
            in these Official Rules will be determined in Sponsor’s sole
            discretion. Sponsor is not responsible for, and will not replace,
            any lost, damaged or stolen reward or reward component or any reward
            that is undeliverable. Winners acknowledge that Sponsor is subject
            to U.S. economic restrictions and trade sanctions; as such, Sponsor
            reserves the right to deny distribution of any reward when required
            by applicable law. Participants waive the right to assert as a cost
            of winning a reward, any costs associated with claiming or seeking
            to claim a reward, or using a reward.
          </li>
          <li>
            ELIGIBILITY. Limited time offer. Slingshot reserves the right to
            revoke any offer at its sole discretion. Slingshot reserves the
            right to change the terms, eligibility criteria and rewards for any
            program, at any time, for any reason.The Promotion is open to anyone
            who is over the age of 18, and located in a jurisdiction where
            Slingshot offers its Services. Void where prohibited. Slingshot
            Users who place a certain volume of trades on Optimism on Slingshot
            during the times specified by Slingshot. Users must visit the
            Promotion Landing Page and place trades via the specified link in
            the promotion to be eligible. Anyone who does not meet the
            conditions in this paragraph is not eligible for this Promotion.
            Slingshot may decline requests to enroll in the Promotion without
            notice and at its sole discretion. The Sponsor may disqualify any
            Entry if the Sponsor reasonably believes that such Entry or entrant
            is: tampering with the entry process or the operation of the
            Promotion, including wallet addresses, that are reasonably
            determined to be fraudulent or duplicates; violating these rules or
            the Terms; acting in an unsportsmanlike or disruptive manner, or
            with intent to annoy, abuse, threaten, or harass any other person;
            or is on any prohibited persons list issued by any governmental
            agency that has regulatory authority over the Sponsor or any of
            Sponsor’s affiliates. Employees, officers and representatives, and
            the immediate family members of such employees, officers and
            representatives (i.e., parent, spouse, siblings, children,
            grandparents, step parents, step children and step siblings, and
            their respective spouses, and those living in the same household,
            whether or not related) of Slingshot are not eligible to enter.
          </li>
          <li>
            AWARDING REWARDS. Sponsor will deliver rewards to each winner’s
            wallet address by the distribution date specified on the Promotion
            Landing Page. Sponsor plans to announce publicly via Twitter and
            Discord that the rewards have been delivered. The Sponsor may deem a
            reward forfeited and reclaim such reward if, within 30 days of the
            Sponsor’s awarding of that reward, the reward winner has not
            fulfilled all obligations required under these rules.
          </li>
          <li>
            WAIVER. By entering the Promotion, you waive any right to, and shall
            hold harmless the Sponsor from, any claim, liability, loss, damage
            (including punitive, incidental, and consequential damages), or
            expense (including attorneys’ fees) arising out of or in connection
            with your participation in the Promotion or the acceptance, use, or
            misuse of any reward. SOME JURISDICTIONS DO NOT ALLOW THE
            LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE DOES NOT APPLY IN ANY SUCH
            JURISDICTION. The Sponsor will not be responsible for: lost, late,
            misdirected, damaged, illegible, incomplete, or incorrect entries;
            lost, late, or misdirected reward notices; or any typographical, or
            other error in the printing of the offer, administration of the
            Promotion, or announcement of the rewards.
          </li>
          <li>
            TAXES. You acknowledge that all income taxes resulting from your
            awarding of a reward are your sole responsibility. The Sponsor will
            not be liable for any tax-related effects of winning or transferring
            a reward. If you win one or more rewards through any of the
            Sponsor’s promotions, totaling an amount in excess of US$600 in any
            calendar year, the Sponsor may be required to report such winnings
            to the Internal Revenue Service and send you an applicable IRS Form
            1099, in which case, you shall provide the Sponsor with any
            necessary information required to complete and send you such form.
            Potential winner(s) should consult an accountant or tax professional
            to determine tax implications in accepting and using (including
            conducting transactions of) any reward.
          </li>
          <li>
            DISCLAIMERS. Sponsor will not be responsible for lost, late,
            damaged, misdirected or mutilated mail, misdirected email, or for
            any technical problems, faulty, lost, garbled, incomplete, incorrect
            or mistranscribed data transmissions, incorrect announcements of any
            kind, malfunctions, technical hardware or software failures of any
            kind including any injury or damage to any person’s computer/mobile
            device related to or resulting from participating in or experiencing
            any materials in connection with this Promotion. Sponsor is not
            responsible for malfunctions or breakdown of any network systems,
            unavailable service connections, lost, incomplete, faulty network
            connectivity of any kind, failures of any service providers, or any
            combination thereof, which may limit a person’s ability to
            participate in this Promotion. Sponsor reserves the right to
            suspend, cancel or modify the Promotion if it cannot be executed as
            planned for any reason including, but not limited to, if fraud,
            human error, technical failures, or any other factor impairs the
            integrity or proper functioning of the Promotion; or if a virus, bug
            or other technical problem corrupts the administration, security, or
            proper play of the Promotion as determined by Sponsor in its sole
            discretion. If the Promotion is so canceled or modified, Sponsor may
            award rewards from among all eligible participants prior to such
            action and Sponsor shall have no further obligation to any
            participant in connection with this Promotion. Sponsor reserves the
            right to prohibit the participation of an individual if fraud or
            tampering is suspected or if the participant fails to comply with
            any requirement of participation as stated herein or with any
            provision in these Official Rules.
          </li>
        </ol>
      </div>
    </>
  )
}

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[2]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
`

const LogoWrapper = styled.div<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  gap: 24px;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`

const SlingshotLogo = styled.div`
  align-items: center;
  display: flex;
  font-family: Sequel Black;
  font-size: 14px;
  gap: 16px;
  letter-spacing: 3px;
`
export default FooterSection
