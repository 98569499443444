import { useEffect, useRef } from 'react'
import { getAnalytics, logEvent } from 'firebase/analytics'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Hero from 'components/Hero'
import TopBar from 'components/TopBar'

import AnaltyicsProvider from 'providers/analytics/AnalyticsProvider'
import { OPTIMISM_LANDING_PAGE_LOAD } from 'providers/analytics/constants'

import FAQSection from 'sections/FAQSection'
import FooterSection from 'sections/FooterSection'
import Aug2022TopBar from 'sections/PastPromos/components/Aug2022TopBar'
import Aug2022Hero from 'sections/PastPromos/components/Aug2022Hero'
import Aug2022TimelineSection from 'sections/PastPromos/components/Aug2022TimelineSection'
import Aug2022WorksSection from 'sections/PastPromos/components/Aug2022WorksSection'
import Aug2022FAQSection from 'sections/PastPromos/components/Aug2022FAQSection'
import Aug2022FooterSection from 'sections/PastPromos/components/Aug2022FooterSection'
import Aug2022PastSwapTerms from 'sections/PastPromos/components/Aug2022PastSwapSection'

import TimelineSection from 'sections/TimelineSection/TimelineSection'
import WorksSection from 'sections/WorksSection'

import Terms from 'Terms'

import { ThemeProvider } from 'theme'

const App: React.FC = () => {
  const sentRef = useRef<boolean>(false)
  const analytics = getAnalytics()

  useEffect(() => {
    if (sentRef.current || !analytics.app.name) return
    logEvent(analytics, OPTIMISM_LANDING_PAGE_LOAD, {
      referrer_url: window.document.referrer || 'direct',
    })
    sentRef.current = true
  }, [analytics])

  return (
    <Router>
      <AnaltyicsProvider>
        <ThemeProvider>
          <Routes>
            <Route path="/past_swap_terms" element={<Aug2022PastSwapTerms />} />
            <Route
              path="/past_swap_terms.html"
              element={<Aug2022PastSwapTerms />}
            />
            <Route path="/promoterms/oct22" element={<Terms />} />
            <Route path="/promoterms/oct22.html" element={<Terms />} />
            <Route
              path="/"
              element={
                <>
                  <TopBar />
                  <Hero />
                  <TimelineSection />
                  <WorksSection />
                  <FAQSection />
                  <FooterSection />
                </>
              }
            />
            <Route
              path="/past/aug22"
              element={
                <>
                  <Aug2022TopBar />
                  <Aug2022Hero />
                  <Aug2022TimelineSection />
                  <Aug2022WorksSection />
                  <Aug2022FAQSection />
                  <Aug2022FooterSection />
                </>
              }
            />
          </Routes>
        </ThemeProvider>
      </AnaltyicsProvider>
    </Router>
  )
}

export default App
