import React from 'react'

import { IconProps } from '../Icon'

const Depth: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path fill={color} d="M2 17H12H22V7L12 17L2 7V17Z" />
  </svg>
)

export default Depth
