import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import useTheme from 'theme/useTheme'

interface LogoProps {
  size?: number
}
const Logo: React.FC<LogoProps> = ({ size = 32 }) => {
  const { textColor } = useTheme()
  return (
    <Wrapper>
      <StyledLogo to="/">
        <svg fill="none" height={size} viewBox="0 0 359 283" overflow="visible">
          <path
            fill={textColor}
            d="M351.99 0.0647624C351.99 0.0647624 183.453 42.3898 158.547 49.4524C68.3035 75.0018 71.8522 147.795 71.8793 148.351L71.8795 148.354C71.19 164.353 89.1153 157.113 89.1153 157.113L246.909 116.636C246.909 116.636 302.035 102.485 326.768 77.7028C351.502 52.9204 358.568 22.6955 357.965 9.07655C357.505 -1.35282 351.99 0.0647624 351.99 0.0647624ZM6.0101 281.075C6.0101 281.075 174.547 238.75 199.453 231.687C290.056 206.044 286.149 132.811 286.149 132.811C286.839 116.812 268.913 124.052 268.913 124.052L111.12 164.504C111.12 164.504 55.9652 178.654 31.2318 203.437C6.49844 228.219 -0.56824 258.444 0.0350317 272.063C0.494657 282.492 6.0101 281.075 6.0101 281.075Z"
          />
        </svg>
      </StyledLogo>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: relative;
  text-decoration: none;
`

export default Logo
