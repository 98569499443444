import React from 'react'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const firebaseConfig = {
  apiKey: 'AIzaSyDB6XsCh1wVftTdTHlexD2XWkh4q81BFEk',
  authDomain: 'slingshot-feb2f.firebaseapp.com',
  databaseURL: 'https://slingshot-feb2f.firebaseio.com',
  projectId: 'slingshot-feb2f',
  storageBucket: 'slingshot-feb2f.appspot.com',
  messagingSenderId: '591613226663',
  appId: '1:591613226663:web:bba6cfa185c3b0b088f582',
  measurementId: 'G-SC5MW18B39',
}

firebase.initializeApp(firebaseConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
