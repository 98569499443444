import animateScrollTo from 'animated-scroll-to'
import ismobilejs from 'ismobilejs'
import { getAnalytics, logEvent } from 'firebase/analytics'
import styled from 'styled-components'

import useDimensions from 'hooks/useDimensions'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Text from 'components/Text'

const Hero: React.FC = () => {
  const [{ width = 0 }, ref] = useDimensions()
  const analytics = getAnalytics()
  const isMobile = ismobilejs(window.navigator).phone

  return (
    <Wrapper isMobile={isMobile}>
      <ContentWrapper>
        <MainWrapper ref={ref}>
          <div>
            <TextWrapper
              style={{
                fontSize: width / 14,
              }}
            >
              Trade on Optimism,
            </TextWrapper>
            <Text black sizePx={width / 14}>
              Get <div style={{ width: '12px' }} />
              <GradientText>
                <i>OP</i> Tokens
              </GradientText>
            </Text>
          </div>
          <div>
            <Text>
              Get up to 12 OP tokens worth $18* when you trade with Slingshot on
              Optimism.
            </Text>
            {/* <div style={{ height: '12px' }} /> */}
          </div>
          <Button
            onClick={() => {
              logEvent(analytics, 'top_trade_now_cta_click')
              window.open(
                'https://app.slingshot.finance/trade/OP?network=optimism',
              )
            }}
            size="lg"
          >
            TRADE NOW
          </Button>
          <Text faded size="xsm">
            Traders must click “Trade Now” from this page to be eligible.
          </Text>
        </MainWrapper>
        <LearnMore
          onClick={() => {
            animateScrollTo(document.getElementById('limited')!)
          }}
        >
          <Icon faded name="chevron-down" size={32} />
        </LearnMore>
      </ContentWrapper>
    </Wrapper>
  )
}

const GradientText = styled.div`
  background-color: #fff;
  background-image: linear-gradient(45deg, rgb(133, 9, 0), rgb(234 52 36));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`

const LearnMore = styled.div`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.spacing[3]}px;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
  background: url(/${({ isMobile }) => (isMobile ? 'mobile' : 'hero')}-bg.jpg)
    no-repeat center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  max-height: 800px;
  min-height: 500px;
  overflow: hidden;
  position: relative;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const ContentWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  left: 0;
  margin: ${(props) => props.theme.spacing[5]}px auto 0;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 80px;
`

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: Sequel Black;
  gap: 24px;
  justify-content: center;
  text-align: center;
  text-shadow: 0px 0px 24px ${(props) => props.theme.colors.grey[900]},
    0px 0px 48px ${(props) => props.theme.colors.grey[900]};
`

export default Hero
