import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import useThrottle from 'hooks/useThrottle'

export interface Dimensions {
  height?: number
  width?: number
  x?: number
  y?: number
}

type UseDimensions = [
  Dimensions,
  (el: HTMLDivElement) => void,
  HTMLDivElement | undefined,
]

const useDimensions = (): UseDimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({})
  const [element, setElement] = useState<HTMLDivElement>()

  const ref = useCallback((el: HTMLDivElement) => {
    setElement(el)
  }, [])

  const handleResize = useCallback(() => {
    if (element) {
      const rect = element.getBoundingClientRect()
      // console.log(rect.y)
      setDimensions({
        height: rect.height,
        width: rect.width,
        x: rect.x,
        y: rect.y + window.scrollY,
      })
    }
  }, [element])

  const throttledResizeHandler = useThrottle(handleResize, 10)

  useEffect(() => {
    window.addEventListener('resize', throttledResizeHandler)
    return () => window.removeEventListener('resize', throttledResizeHandler)
  }, [throttledResizeHandler])

  useLayoutEffect(() => {
    if (!element) {
      return
    }
    const observer = new ResizeObserver(throttledResizeHandler)
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [element, throttledResizeHandler])

  return [dimensions, ref, element]
}

export default useDimensions
