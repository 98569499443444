import React from "react";
import styled, { keyframes } from "styled-components";

interface LoaderProps {
  color?: string;
  size?: number;
}
const Loader: React.FC<LoaderProps> = ({ color, size = 48 }) => {
  let thickness = 3;
  if (size <= 24) {
    thickness = 2;
  } else if (size >= 64) {
    thickness = 4;
  }

  const green = "#66FF99";
  const black = "#000000";
  return (
    <Wrapper size={size}>
      <Inner color={color || black} one thickness={thickness} />
      <Inner color={color || black} two thickness={thickness} />
      <Inner color={color || black} three thickness={thickness} />
    </Wrapper>
  );
};

interface WrapperProps {
  size: number;
}

const Wrapper = styled.div<WrapperProps>`
  border-radius: 50%;
  height: ${(props) => props.size}px;
  position: relative;
  width: ${(props) => props.size}px;
`;

interface InnerProps {
  one?: boolean;
  thickness: number;
  three?: boolean;
  two?: boolean;
}

const oneKeyframes = keyframes`
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
`;

const twoKeyframes = keyframes`
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
`;

const threeKeyframes = keyframes`
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
`;

const Inner = styled.div<InnerProps>`
  animation: ${(props) =>
      props.one ? oneKeyframes : props.two ? twoKeyframes : threeKeyframes}
    1s linear infinite;
  border-bottom: ${(props) =>
    props.one ? `${props.thickness}px solid ${props.color}` : undefined};
  border-radius: 50%;
  border-right: ${(props) =>
    props.two ? `${props.thickness}px solid ${props.color}` : undefined};
  border-top: ${(props) =>
    props.three ? `${props.thickness}px solid ${props.color}` : undefined};
  box-sizing: border-box;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export default Loader;
