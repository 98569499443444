import React from 'react'

import { IconProps } from '../Icon'

const Warning: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" fill={color} />
  </svg>
)

export default Warning
