import React from 'react'

import { IconProps } from '../Icon'

const ArrowRight: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path fill={color} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
  </svg>
)

export default ArrowRight
