import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import Text from 'components/Text'
import TextButton from 'components/TextButton'

const items = [
  {
    question: 'Why use Slingshot?',
    answer: `
    Slingshot is a desktop app that lets you buy, sell, and swap over 40k cryptocurrencies at the best prices, with 0% swap fees. With a sleek and intuitive interface, Slingshot combines the ease of centralized exchanges, with the openness and transparency of defi. 

    Simply connect your web3 wallet to use Slingshot for trading, while keeping full control over your private keys and assets. Real-time market data and charts are available for each token, and a global chat for discussing the markets with people all over the world.

    Swapping is available on L1 and L2 networks including Canto, Polygon, Optimism, Arbitrum, and BNB.
`,
  },
  {
    question: 'How long does the promo run?',
    answer: `The Optimism token promo will last one week and only while supplies last. The promo begins September 19th, 2022 at 10:00am PST and ends September 25th, 2022 at 11:59pm PST. The promo may end sooner if the token supply runs out.`,
  },
  {
    question: 'When will the tokens be delivered?',
    answer: `Optimism tokens will be distributed by September 30th, 2022 by 11:59pm PST.
    `,
  },
  {
    question: 'Are wraps/unwraps considered a swap?',
    answer: `Wraps (e.g., ETH → WETH) and unwraps (e.g., WETH → ETH) are not considered swaps.`,
  },
  {
    question: 'How are rewards determined?',
    answer: `Rewards are determined based on eligible users’ swap volume on the Optimism chain during the promo period (see “How long does the promo run?” above).
    `,
  },
  {
    question: 'Can I swap across multiple wallets?',
    answer: `No, we will be giving 1 reward per person. Any attempt to game the promo may disqualify you from rewards.`,
  },
]

type ExpandedState = {
  [key in number]: boolean
}

const WorksSection: React.FC = () => {
  const initialState: ExpandedState = items.reduce(
    (a, _, i) => ({ ...a, [i]: false }),
    {},
  )
  const [expanded, setExpanded] = useState<ExpandedState>(initialState)

  const handleToggle = useCallback(
    (i: number) => {
      setExpanded((e) => {
        const toggledState = { ...initialState }
        toggledState[i] = !e[i]
        return toggledState
      })
    },
    [initialState],
  )

  return (
    <Wrapper id="limited">
      <ContentWrapper>
        <MainWrapper>
          <Text black size="lg">
            FAQs
          </Text>
          <FAQWrapper>
            {items.map(({ answer, question }, i) => (
              <React.Fragment key={question}>
                <TextButton
                  faded={!expanded[i]}
                  onClick={() => handleToggle(i)}
                  textAlign="center"
                >{`${expanded[i] ? '-' : '+'} ${question}`}</TextButton>
                {expanded[i] ? (
                  <Text lineHeight={1.5} textAlign="center">
                    {answer}
                  </Text>
                ) : null}
              </React.Fragment>
            ))}
          </FAQWrapper>
        </MainWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0 0 122px 0;
  position: relative;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const FAQWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  text-align: center;
`

export default WorksSection
