import styled from 'styled-components'

import Text from 'components/Text'

type Props = { title: string; month: string; day: number; timeString: string }

const Card: React.FC<Props> = ({ month, day, timeString, title }) => {
  // const date = new Date(timestamp)
  // const month = date.toLocaleString('en-us', { month: 'long' })
  // const day = date.getDate()
  // const time = date.toLocaleString('en-us', {
  //   hour: 'numeric',
  //   minute: 'numeric',
  // })
  // const dateString = `${time} PST`
  return (
    <div>
      <Top>
        <Text black sizePx={20}>
          {title}
        </Text>
      </Top>
      <Bottom>
        <Text black size="sm">
          {month}
        </Text>
        <Text black size="xlg">
          {day}
        </Text>
        <Text black size="xsm">
          {timeString}
        </Text>
      </Bottom>
    </div>
  )
}

const Top = styled.div`
  border-bottom: 1px solid rgb(42 40 45);
  border-top: 1px solid rgb(42 40 45);
  padding: ${(props) => props.theme.spacing[1]}px;
`

const Bottom = styled.div`
  align-items: center;
  background-color: rgb(18 16 21);
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[3]}px
    ${(props) => props.theme.spacing[3]}px
    ${(props) => props.theme.spacing[4]}px;
`

export default Card
