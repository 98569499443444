import React from 'react'

import styled from 'styled-components'

import Icon, { Icons } from 'components/Icon'
import Text, { TextProps } from 'components/Text'

interface TextButtonProps extends TextProps {
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  icon?: Icons
}

const TextButton: React.FC<TextButtonProps> = ({
  color,
  children,
  disabled,
  onClick,
  faded,
  glow,
  icon,
  ...textProps
}) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      <Text
        color={color}
        faded={faded || disabled}
        glow={glow}
        weight="bold"
        {...textProps}
      >
        {children}
      </Text>
      {!!icon && (
        <IconWrapper>
          <Icon color={color} faded={faded || disabled} name={icon} size={18} />
        </IconWrapper>
      )}
    </Button>
  )
}

const Button = styled.button`
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.textColor};
  cursor: ${(props) => !props.disabled && 'pointer'};
  display: inline-flex;
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: left;
  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : props.theme.fadedOpacity)};
  }
`

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 14px;
  justify-content: center;
  width: 14px;
`

export default TextButton
