import React from 'react'
import styled from 'styled-components'

import Icon, { Icons } from 'components/Icon'
import useTheme from 'theme/useTheme'

interface IconButtonProps {
  active?: boolean
  border?: boolean
  color?: string
  disabled?: boolean
  faded?: boolean
  iconSize?: number
  name?: Icons
  size?: 'sm' | 'md' | 'lg' | 'xsm'
  onClick?: (e: React.MouseEvent) => void
}

const IconButton: React.FC<IconButtonProps> = ({
  active,
  border,
  color,
  disabled,
  faded,
  iconSize,
  name,
  size = 'md',
  onClick,
}) => {
  const { borderColor, inputSizes, textColor } = useTheme()
  let buttonSize: number
  switch (size) {
    case 'sm':
      buttonSize = inputSizes.sm
      break
    case 'lg':
      buttonSize = inputSizes.lg
      break
    case 'xsm':
      buttonSize = 24
      break
    case 'md':
    default:
      buttonSize = inputSizes.md
  }
  return (
    <Wrapper>
      <StyledButton
        active={active}
        onClick={onClick}
        size={buttonSize}
        style={{
          border: border ? `1px solid ${borderColor}` : 0,
          opacity: disabled ? 0.25 : undefined,
          pointerEvents: disabled ? 'none' : undefined,
        }}
      >
        {!!name && (
          <Icon
            color={color || textColor}
            faded={faded}
            name={name}
            size={iconSize}
          />
        )}
      </StyledButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  &:hover > * {
    opacity: 0.5 !important;
  }
`

interface StyledButtonProps {
  active?: boolean
  disabled?: boolean
  size: number
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background: transparent;
  border-radius: ${(props) => props.theme.borderRadius}px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: ${(props) => props.size}px;
  justify-content: center;
  margin: 0;
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  outline: none;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  white-space: nowrap;
`

export default IconButton
