import React from 'react'

import { IconProps } from '../Icon'

const ArrowUpward: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
      fill={color}
    />
  </svg>
)

export default ArrowUpward
