import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import IconButton from 'components/IconButton'
import Logo from 'components/Logo'
import TextButton from 'components/TextButton'
import Text from 'components/Text'

const TopBar: React.FC = () => {
  const isMobile = ismobilejs(window.navigator).phone
  return (
    <Wrapper>
      <CountdownBar>
        <Text textAlign="center" weight="bold">
          Promo has ended
        </Text>
      </CountdownBar>
      <TopBarWrapper>
        <LogoWrapper>
          <Logo size={isMobile ? 18 : 32} />
          <HideOnMobile>SLINGSHOT</HideOnMobile>
        </LogoWrapper>
        <Nav>
          <TextButton
            onClick={() => {
              window.open('https://app.slingshot.finance/trade/ETH')
            }}
            sizePx={14}
          >
            Trade
          </TextButton>
          <TextButton
            onClick={() => {
              window.open('https://app.slingshot.finance/markets')
            }}
            sizePx={14}
          >
            Prices
          </TextButton>
          <TextButton
            onClick={() => {
              window.open('https://app.slingshot.finance/nfts')
            }}
            sizePx={14}
          >
            NFTs
          </TextButton>
          <div style={{ flex: 1 }} />
          <IconButton
            color="white"
            onClick={() =>
              window.open('https://twitter.com/SlingshotCrypto', '__blank')
            }
            name="twitter"
            iconSize={14}
            size="xsm"
          />
          <IconButton
            color="white"
            name="discord"
            iconSize={14}
            onClick={() =>
              window.open('https://discord.com/invite/H3UcvgtASR', '__blank')
            }
            size="xsm"
          />
        </Nav>
      </TopBarWrapper>
    </Wrapper>
  )
}

const CountdownBar = styled.div`
  align-items: center;
  background-color: rgb(234 52 36);
  color: black;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 100%;
`

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: Sequel Black;
  font-size: 14px;
  gap: 16px;
  height: 24px;
  letter-spacing: 3px;
`

const HideOnMobile = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`

const Nav = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  width: 100%;
`

const Wrapper = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 500;
`

const TopBarWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.grey[900]};
  display: flex;
  height: 64px;
  padding: 0 ${(props) => props.theme.spacing[3]}px;
`

export default TopBar
