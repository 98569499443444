import React from 'react'

import { IconProps } from '../Icon'

const SyncAlt: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={color} d="M22,8l-4-4v3H3v2h15v3L22,8z" />
    <path fill={color} d="M2,16l4,4v-3h15v-2H6v-3L2,16z" />
  </svg>
)

export default SyncAlt
