import React from 'react'

import { IconProps } from '../Icon'

const DoubleArrowDown: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path
      fill={color}
      d="M5.25 10.7499V15.2499L12.25 20.2499L19.25 15.2499V10.7499L12.25 15.7499L5.25 10.7499Z"
    />
    <path
      fill={color}
      d="M5.25 3.75015V8.25015L12.25 13.2502L19.25 8.25015V3.75015L12.25 8.75015L5.25 3.75015Z"
    />
  </svg>
)

export default DoubleArrowDown
