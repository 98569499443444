import React from 'react'

import { IconProps } from '../Icon'

const Remove: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 13H5v-2h14v2z" fill={color} />
  </svg>
)

export default Remove
