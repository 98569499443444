import React from 'react'

import { IconProps } from '../Icon'

const DoubleArrowDown: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path
      fill={color}
      d="M5.25 13.2502V8.75015L12.25 3.75015L19.25 8.75015V13.2502L12.25 8.25015L5.25 13.2502Z"
    />
    <path
      fill={color}
      d="M5.25 20.2499V15.7499L12.25 10.7499L19.25 15.7499V20.2499L12.25 15.2499L5.25 20.2499Z"
    />
  </svg>
)

export default DoubleArrowDown
