import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Theme } from './types'

const useTheme = () => {
  const themeContext = useContext(ThemeContext) as Theme
  return themeContext
}

export default useTheme
