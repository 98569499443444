import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import Text from 'components/Text'

import Card from '../../../TimelineSection/components/Card'

const items = [
  {
    day: 15,
    month: 'August',
    timeString: '10:00 AM PST',
    title: 'Promo starts',
  },
  {
    day: 21,
    month: 'August',
    timeString: '11:59 PM PST',
    title: 'Promo ends',
  },
  {
    day: 26,
    month: 'August',
    timeString: '11:59 PM PST',
    title: 'Distribution',
  },
]

const TimelineSection: React.FC = () => {
  const isMobile = ismobilejs(window.navigator).phone
  return (
    <Wrapper id="limited" isMobile={isMobile}>
      <ContentWrapper>
        <MainWrapper>
          <Text black size="lg">
            Limited time only
          </Text>
          <CardsWrapper isMobile={isMobile}>
            {items.map((item) => (
              <Card key={item.title} {...item} />
            ))}
          </CardsWrapper>
          <div>
            <Text>While 50K token supplies last.</Text>
            <div style={{ height: '12px' }} />
            <Text faded size="sm">
              End date is subject to change if trading volumes are larger than
              anticipated, due to the limited number of tokens in OP reward
              pool.
            </Text>
          </div>
        </MainWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const CardsWrapper = styled.div<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '61px' : '122px')} 0 122px;
  overflow: hidden;
  position: relative;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

export default TimelineSection
