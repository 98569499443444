import React from 'react'

import { IconProps } from '../Icon'

const ArrowSouthEast: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path fill={color} d="M19,9h-2v6.59L5.41,4L4,5.41L15.59,17H9v2h10V9z" />
  </svg>
)

export default ArrowSouthEast
