import React from 'react'
import styled from 'styled-components'

import { lighten } from 'theme/helpers'
import useTheme from 'theme/useTheme'

type FontSize = 'lg' | 'md' | 'sm' | 'xlg' | 'xsm'
type FontWeight = 'regular' | 'medium' | 'bold'

export interface TextProps {
  align?: 'center' | 'flex-start' | 'flex-end' | 'baseline'
  black?: boolean
  children?: React.ReactNode
  color?: string
  faded?: boolean
  glow?: boolean
  lineHeight?: number
  maxWidth?: number
  monospace?: boolean
  singleLine?: boolean
  size?: FontSize
  sizePx?: number
  spread?: boolean
  textAlign?: 'center' | 'left' | 'right'
  weight?: FontWeight
}

const Text: React.FC<TextProps> = ({
  align = 'center',
  black,
  children,
  color,
  faded,
  glow,
  lineHeight = 1,
  maxWidth,
  monospace,
  singleLine,
  size,
  sizePx = 18,
  spread,
  textAlign,
  weight,
}) => {
  const { fontSizes, mode } = useTheme()
  const fadedOpacity = mode === 'dark' ? 0.5 : 0.66
  return (
    <StyledText
      color={color}
      fontSize={size ? fontSizes[size] : sizePx}
      fontWeight={getFontWeight(weight)}
      glow={glow}
      spread={spread}
      maxWidth={maxWidth}
      opacity={faded ? fadedOpacity : undefined}
      style={{
        alignItems: align,
        display: singleLine ? 'block' : undefined,
        fontFamily: black
          ? 'Sequel Black'
          : monospace
          ? 'IBM Plex Mono'
          : undefined,
        lineHeight,
        textAlign,
        whiteSpace: singleLine ? 'nowrap' : undefined,
      }}
    >
      {children}
    </StyledText>
  )
}

function getFontWeight(weight?: FontWeight) {
  switch (weight) {
    case 'bold':
      return 700
    case 'medium':
      return 500
    case 'regular':
    default:
      return 400
  }
}

interface StyledTextProps {
  color?: string
  fontSize: number
  fontWeight?: number
  glow?: boolean
  maxWidth?: number
  opacity?: number
  spread?: boolean
}

const StyledText = styled.span<StyledTextProps>`
  color: ${(props) =>
    props.color && props.glow ? lighten(props.color, 95) : props.color};
  display: inline-flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => (props.spread ? '0.1em' : undefined)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : undefined)};
  opacity: ${(props) => props.opacity};
  overflow: ${(props) => (props.maxWidth ? 'hidden' : undefined)};
  text-shadow: ${(props) =>
    props.glow
      ? `0px 0px ${props.fontSize / 2}px ${
          props.color ? props.color : props.theme.moodColor
        };`
      : undefined};
  text-overflow: ${(props) => (props.maxWidth ? 'ellipsis' : undefined)};
`

export default Text
