import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import Text from 'components/Text'
import TextButton from 'components/TextButton'

const items = [
  {
    question: 'Why trade on Slingshot?',
    answer: `
    Slingshot is a web3 trading platform that aggregates liquidity on networks like Ethereum, to offer the best prices on thousands of crypto assets. Slingshot combines the performance and ease of centralized exchanges, with the openness and transparency of decentralized exchanges.

Trade 40k+ tokens at the best prices (and keep any positive slippage), access real time data and charts for every token, and connect with traders all over the world in the global chat.
`,
  },
  {
    question: 'What is Layer3?',
    answer: `Layer3 is a user acquisition platform to drive discovery, targeted conversions, & retention for platforms across EVM compatible chains. Since launch, they have processed over 750,000 conversions (swaps, LPs, bridges, etc.) on behalf of dozens of platforms.`,
  },
  {
    question: 'How long does the promo run?',
    answer: `The Optimism token promo will last one week and only while supplies last. The promo begins August 15th, 2022 at 10:00am PST and ends August 21st, 2022 at 11:59pm PST. The promo may end sooner if the token supply runs out.`,
  },
  {
    question: 'When will the tokens be distributed?',
    answer: `Optimism tokens will be distributed on August 26th, 2022 by 11:59pm PST.`,
  },
  {
    question: 'Are wraps/unwraps considered a trade?',
    answer: `Wraps (e.g., ETH → WETH) and unwraps (e.g., WETH → ETH) are not considered trades.`,
  },
  {
    question: 'How are rewards determined?',
    answer: `Rewards are determined based on eligible users’ trade volume on the Optimism chain during the promo period (see “How long does the promo run?” above).`,
  },
  {
    question: 'Can I trade across multiple wallets?',
    answer: `No, we will be giving 1 reward per person. Any attempt to game the promo may disqualify you from rewards.`,
  },
]

type ExpandedState = {
  [key in number]: boolean
}

const Aug2022FAQSection: React.FC = () => {
  const initialState: ExpandedState = items.reduce(
    (a, _, i) => ({ ...a, [i]: false }),
    {},
  )
  const [expanded, setExpanded] = useState<ExpandedState>(initialState)

  const handleToggle = useCallback(
    (i: number) => {
      setExpanded((e) => {
        const toggledState = { ...initialState }
        toggledState[i] = !e[i]
        return toggledState
      })
    },
    [initialState],
  )

  return (
    <Wrapper id="limited">
      <ContentWrapper>
        <MainWrapper>
          <Text black size="lg">
            FAQs
          </Text>
          <FAQWrapper>
            {items.map(({ answer, question }, i) => (
              <React.Fragment key={question}>
                <TextButton
                  faded={!expanded[i]}
                  onClick={() => handleToggle(i)}
                  textAlign="center"
                >{`${expanded[i] ? '-' : '+'} ${question}`}</TextButton>
                {expanded[i] ? (
                  <Text lineHeight={1.5} textAlign="center">
                    {answer}
                  </Text>
                ) : null}
              </React.Fragment>
            ))}
          </FAQWrapper>
        </MainWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0 0 122px 0;
  position: relative;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

const FAQWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  text-align: center;
`

export default Aug2022FAQSection
