import React from 'react'

import { IconProps } from '../Icon'

const ChevronDown: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={color} d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
)

export default ChevronDown
