import { getAnalytics, logEvent } from 'firebase/analytics'
import ismobilejs from 'ismobilejs'
import styled from 'styled-components'

import Button from 'components/Button'
import Text from 'components/Text'

const Aug2022WorksSection: React.FC = () => {
  const analytics = getAnalytics()
  const isMobile = ismobilejs(window.navigator).phone

  const LastColumn = isMobile ? (
    <Column>
      <div style={{ paddingBottom: '12px' }}>
        <Text black>REWARDS</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <Text size="xsm">1 OP token</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <Text size="xsm">3 OP token</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <Text size="xsm">6 OP token</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <Text size="xsm">12 OP token</Text>
      </div>
    </Column>
  ) : (
    <Column>
      <div style={{ paddingBottom: '12px' }}>
        <Text black>REWARDS</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <img alt="" height="40px" src="/opt-1.png" width="auto" />
        <Text size="xsm">1 OP token</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <img alt="" height="40px" src="/opt-3.png" width="auto" />
        <Text size="xsm">3 OP tokens</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <img alt="" height="40px" src="/opt-6.png" width="auto" />
        <Text size="xsm">6 OP tokens</Text>
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          height: '96px',
          justifyContent: 'center',
        }}
      >
        <img alt="" height="40px" src="/opt-12.png" width="auto" />
        <Text size="xsm">12 OP tokens</Text>
      </div>
    </Column>
  )

  return (
    <Wrapper id="limited">
      <ContentWrapper>
        <MainWrapper>
          <Text black size="lg">
            How it works
          </Text>
          <Text>
            The more volume you trade on Optimism, the more tokens you can get.
          </Text>
          <Row>
            <Column>
              <div style={{ paddingBottom: '12px' }}>
                <Text black textAlign="center">
                  TIER
                </Text>
              </div>
              <div style={{ flex: 1, padding: '18px 0' }}>
                <Text black size="xlg">
                  1
                </Text>
              </div>
              <div style={{ flex: 1, padding: '18px 0' }}>
                <Text black size="xlg">
                  2
                </Text>
              </div>
              <div style={{ flex: 1, padding: '18px 0' }}>
                <Text black size="xlg">
                  3
                </Text>
              </div>
              <div style={{ flex: 1, padding: '18px 0' }}>
                <Text black size="xlg">
                  4
                </Text>
              </div>
            </Column>
            <Column>
              <div style={{ paddingBottom: '12px' }}>
                <Text black>{isMobile ? 'TRADE' : 'TRADING REQUIRED'}</Text>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '96px',
                  justifyContent: 'center',
                }}
              >
                <Text>$100 - $299</Text>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '96px',
                  justifyContent: 'center',
                }}
              >
                <Text>$300 - $599</Text>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '96px',
                  justifyContent: 'center',
                }}
              >
                <Text>$600 - $1199</Text>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '96px',
                  justifyContent: 'center',
                }}
              >
                <Text>$1200+</Text>
              </div>
            </Column>
            {LastColumn}
          </Row>
          <Button
            onClick={() => {
              logEvent(analytics, 'bottom_trade_now_cta_click')
              window.open(
                'https://app.slingshot.finance/trade/OP?network=optimism',
              )
            }}
            size="lg"
          >
            TRADE NOW
          </Button>
        </MainWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

const Column = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex: 1;
  // gap: ${(props) => props.theme.spacing[4]}px;
  min-height: 100%;
  & > *:nth-child(even) {
    background-color: rgb(18 16 21);
  }
`

const Row = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props) => props.theme.spacing[1]}px;
  min-width: 100%;
  justify-content: center;
`

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0 0 122px 0;
  position: relative;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]}px;
  justify-content: center;
  margin: 0 auto;
  max-width: ${(props) => props.theme.siteWidth}px;
  overflow: hidden;
  padding: 0 ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const MainWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]}px;
  justify-content: center;
  width: 100%;
  max-width: 900px;
`

export default Aug2022WorksSection
