import React, { useMemo } from 'react'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { lighten } from 'theme/helpers'
import useTheme from 'theme/useTheme'

export interface ButtonProps {
  children?: React.ReactNode
  color?: 'bearish' | 'bullish' | 'black'
  disabled?: boolean
  full?: boolean
  href?: string
  iconFaded?: boolean
  noPadding?: boolean
  onClick?: (e: React.MouseEvent) => void
  round?: boolean
  size?: 'sm' | 'md' | 'lg'
  text?: string
  textColor?: string
  to?: string
  variant?: 'default' | 'outlined' | 'floating'
}

const Button: React.FC<ButtonProps> = ({
  children,
  color,
  disabled,
  full,
  href,
  iconFaded,
  noPadding,
  onClick,
  round = true,
  size,
  text,
  to,
  variant,
}) => {
  const {
    borderColor,
    colors,
    fontSizes,
    inputSizes,
    mode,
    spacing,
    textColor,
  } = useTheme()

  let buttonSize: number
  let buttonPadding: number
  let fontSize: number

  const themeColor = color ? colors[color] : undefined

  switch (size) {
    case 'sm':
      buttonPadding = spacing[3]
      buttonSize = inputSizes.sm
      fontSize = fontSizes.sm
      break
    case 'lg':
      buttonPadding = spacing[4]
      buttonSize = inputSizes.lg
      fontSize = fontSizes.md
      break
    case 'md':
    default:
      buttonPadding = spacing[3]
      buttonSize = inputSizes.md
      fontSize = fontSizes.md
  }

  let background: string
  let backgroundHover: string
  let border: string
  let boxShadow = ''
  let buttonTextColor: string
  let buttonTextColorHover: string

  const bgColor = useMemo(() => {
    switch (mode) {
      case 'dark':
        return themeColor || colors.primary.main
      case 'light':
      default:
        return themeColor || colors.primary.main
    }
  }, [colors.primary.main, mode, themeColor])

  switch (variant) {
    case 'floating':
      background = 'transparent'
      backgroundHover = 'transparent'
      border = '0'
      buttonTextColor = themeColor || textColor
      buttonTextColorHover = (themeColor || textColor) + 'AA'
      break
    case 'outlined':
      background = 'transparent'
      backgroundHover = 'transparent'
      border = `1px solid ${borderColor}`
      buttonTextColor = themeColor || textColor
      buttonTextColorHover = (themeColor || textColor) + 'aa'
      break
    case 'default':
    default:
      background = bgColor
      backgroundHover = mode === 'dark' ? lighten(bgColor) : bgColor
      border = ''
      boxShadow = ``
      buttonTextColor = mode === 'dark' ? colors.black : colors.white
      buttonTextColorHover = mode === 'dark' ? colors.black : colors.white
  }

  const ButtonText = useMemo(() => {
    if (!text) {
      return null
    }
    return <StyledButtonText>{text}</StyledButtonText>
  }, [text])

  const ButtonChild = useMemo(() => {
    if (to) {
      return (
        <StyledLink to={to}>
          {ButtonText}
          {children}
        </StyledLink>
      )
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {ButtonText}
          {children}
        </StyledExternalLink>
      )
    } else {
      return (
        <>
          {ButtonText}
          {children}
        </>
      )
    }
  }, [children, ButtonText, href, to])

  return (
    <StyledButton
      background={background}
      backgroundHover={backgroundHover}
      border={border}
      boxShadow={boxShadow}
      color={buttonTextColor}
      colorHover={buttonTextColorHover}
      disabled={disabled}
      fontSize={fontSize}
      full={full}
      onClick={onClick}
      padding={!noPadding ? buttonPadding : 0}
      round={round}
      size={buttonSize}
    >
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  background: string
  backgroundHover: string
  border?: string
  boxShadow?: string
  color: string
  colorHover: string
  disabled?: boolean
  fontSize: number
  full?: boolean
  padding: number
  round?: boolean
  size: number
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background: ${(props) => props.background};
  border: ${(props) => (props.border ? props.border : 0)};
  border-radius: ${(props) => (props.round ? props.theme.borderRadius : 0)}px;
  box-shadow: ${(props) => props.boxShadow};
  box-sizing: border-box;
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  height: ${(props) => props.size}px;
  justify-content: center;
  margin: 0;
  min-width: ${(props) => props.size}px;
  opacity: ${(props) => (props.disabled ? props.theme.fadedOpacity : 1)};
  outline: none;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  text-transform: capitalize;
  user-select: none;
  white-space: nowrap;
  width: ${(props) => (props.full ? '100%' : undefined)};
  &:hover {
    background: ${(props) => props.backgroundHover};
    color: ${(props) => props.colorHover};
  }
`

const StyledButtonText = styled.span`
  opacity: 1;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button
