import React from 'react'

import { IconProps } from '../Icon'

const ArrowNorthEast: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path fill={color} d="M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9z" />
  </svg>
)

export default ArrowNorthEast
