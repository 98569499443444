import React from 'react'

import { IconProps } from '../Icon'

const Pause: React.FC<IconProps> = ({ color, size = 24 }) => (
  <svg height={size} viewBox="0 0 24 24" width={size}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill={color} d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </svg>
)

export default Pause
