import { createContext } from 'react'

import { GAEventRequest } from './types'

interface AnaltyicsContextValue {
  onSendGAEvent: (event: GAEventRequest) => void
}

const AnaltyicsContext = createContext<AnaltyicsContextValue>({
  onSendGAEvent: () => {},
})

export default AnaltyicsContext
